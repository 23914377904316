<template>
  <div class="upload">
    <div class="header">
      <h2>图片上传测试模块</h2>
    </div>
    <div class="upload-section">
      <input type="file" ref="fileInput" class="file-input" />
      <button @click="uploadFile" class="upload-button">上传文件</button>
    </div>
    <div v-if="uploadProgress > 0" class="progress-container">
      <div class="progress-bar" :style="{ width: `${uploadProgress}%` }"></div>
    </div>
    <div v-if="imageUrl" class="image-container">
      <img :src="imageUrl" alt="Uploaded Image" class="uploaded-image" />
    </div>
  </div>
</template>

<script>
  import { fileUpload } from "@/api"; // 确保引入路径正确

  export default {
  name: "AdminUpload",
  data() {
  return {
  imageUrl: null, // 用于存储返回的图片 URL
};
},
  methods: {
  async uploadFile() {
  const fileInput = this.$refs.fileInput;
  const file = fileInput.files[0];

  if (!file) {
  console.error("请选择文件");
  return;
}

      try {
            // 调用 fileUpload API，将文件传递给接口
            const response = await fileUpload(file);

            if (!response || !response.success) {
            throw new Error("上传失败");
          }

            // 更新 imageUrl 以显示返回的图片
            this.imageUrl = response.success;
            console.log("上传成功:", response);

          } catch (error) {
            console.error("上传出错:", error);
          }
      },
    },
};
</script>

<style scoped>
.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.header {
  margin-bottom: 20px;
}

h2 {
  color: #333;
  font-size: 1.5em;
  font-weight: 600;
}

.upload-section {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.file-input {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.upload-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #0056b3;
}

.progress-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.progress-bar {
  height: 10px;
  background-color: #007bff;
  transition: width 0.2s;
}

.image-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.uploaded-image {
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
