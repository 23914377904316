<template>
  <div class="setting">
    <h2>系统设置</h2>
    <p>这里是系统设置模块。</p>

    <!-- 表格形式的配置 -->
    <table class="config-table">
      <thead>
      <tr>
        <th>配置项</th>
        <th>值</th>
        <th>操作</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Token</td>
        <td><input v-model="tokenInput" placeholder="请输入 Token" /></td>
        <td>
          <button @click="saveToken">保存</button>
          <span v-if="saveSuccess" class="success-message">保存成功</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'AdminSetting',
  setup() {
    const tokenInput = ref(localStorage.getItem('test_token') || ''); // 初始化输入框内容
    const saveSuccess = ref(false); // 控制保存成功提示的显示

    const saveToken = () => {
      localStorage.setItem('test_token', tokenInput.value); // 保存 Token 到本地存储
      saveSuccess.value = true; // 显示保存成功提示

      // 3 秒后隐藏提示
      setTimeout(() => {
        saveSuccess.value = false;
      }, 3000);
    };

    return {
      tokenInput,
      saveSuccess,
      saveToken,
    };
  },
};
</script>

<style scoped>
.setting {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #0056b3;
}

p {
  font-size: 1em;
  margin-bottom: 20px;
  color: #666;
}

.config-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 20px;
}

.config-table th,
.config-table td {
  padding: 15px 20px;
  text-align: left;
}

.config-table th {
  background-color: #f5f8fa;
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid #e0e0e0;
}

.config-table td {
  border-bottom: 1px solid #e0e0e0;
}

.config-table tr:hover td {
  background-color: #f9f9f9;
}

.config-table input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  color: #333;
}

.config-table input:focus {
  border-color: #007bff;
  outline: none;
}

.config-table button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s, box-shadow 0.2s;
}

.config-table button:hover {
  background-color: #0056b3;
}

.config-table button:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);
}

/* 保存成功提示样式 */
.success-message {
  margin-left: 10px;
  color: #28a745;
  font-weight: bold;
  transition: opacity 0.3s;
  opacity: 0;
  animation: fadeInOut 3s ease-in-out forwards;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}
</style>
