<template>
  <div class="api">
  <div class="header">
      <h2>接口管理</h2>
    </div>
  </div>
 <!-- 用户列表表格 -->
 <el-table :data="users" v-loading="loading" style="width: 100%">
      <el-table-column prop="ID" label="用户ID" width="100"></el-table-column>
      <el-table-column prop="Provider" label="类型"></el-table-column>
      <el-table-column prop="EndPointUrl" label="接口Url"></el-table-column>
      <el-table-column prop="ApiKey" label="密钥"></el-table-column>
      <el-table-column prop="description" label="简介"></el-table-column>
      <el-table-column prop="model" label="模型名称"></el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="更新时间"></el-table-column>
      <el-table-column label="禁用状态">
        <template v-slot="scope">
          <el-switch
              v-model="scope.row.isDisabled"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="toggleStatus(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>

      <!-- 操作列 -->
      <el-table-column label="操作" width="180">
        <template v-slot="scope">
          <div class="button-group">
            <el-button size="small" @click="editUser(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" @click="openDeleteDialog(scope.row.ID)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>


</template>

<script>
export default {
  name: 'AdminAPI',
 
};
</script>

<style scoped>
.api {
  padding: 20px;
}

h2 {
  color: #333;
}
</style>
