import { createRouter, createWebHistory } from 'vue-router';
import AdminLogin from '@/views/Login.vue';
import AdminUserManagement from '@/views/UserManagement.vue';
import AdminHome from '@/views/Home.vue';
import AdminUser from '@/views/User.vue';
import AdminApi from '@/views/Api.vue';
import AdminQuestion from '@/views/Question.vue'
import AdminLog from '@/views/Log.vue';
import AdminSetting from '@/views/Setting.vue';
import StreamQuestion from '@/views/StreamQuestion.vue';
import StreamFile from '@/views/Upload.vue'



const routes = [
  { path: '/', redirect: '/login' }, // Root path redirects to login page
  { path: '/login', component: AdminLogin },
  {
    path: '/admin',
    component: AdminUserManagement,
    children: [
      { path: '', redirect: '/admin/home' }, // Default child route to redirect to home
      { path: 'home', name: 'AdminHome', component: AdminHome },
      { path: 'user', name: 'AdminUser', component: AdminUser },
      { path: 'api', name: 'AdminApi', component: AdminApi },
      { path: 'question', name:'AdminQuestion',component: AdminQuestion},
      { path: 'log',name:'AdminLog',component:AdminLog},
      { path: 'setting', name: 'AdminSetting', component: AdminSetting },
      {path:'teststream',name:'StreamQuestion',component:StreamQuestion},
      {path:'streamfile',name:'StreamFile',component:StreamFile},
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 全局路由守卫，检查用户是否已登录
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token'); // 从 localStorage 中获取 token
  const isAuthenticated = !!token; // 检查 token 是否存在，作为是否已登录的标志

  // 如果尝试访问受保护的路由（如 /admin），并且没有登录，则重定向到登录页面
  if (to.path.startsWith('/admin') && !isAuthenticated) {
    next('/login'); // 跳转到登录页面
  } else {
    next(); // 继续正常访问
  }
});


export default router;
