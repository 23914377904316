<template>
  <div class="Log">
    <h2>日志管理</h2>
    <p>这里是日志管理模块。</p>
  </div>
</template>

<script>
export default {
  name: 'AdminQuestion',
};
</script>

<style scoped>
.Log {
  padding: 20px;
}

h2 {
  color: #333;
}
</style>
