// src/api.js
import axios from 'axios';

// 创建 axios 实例
const api = axios.create({
    baseURL: 'https://chatapi.alimzs.com/api',
  // baseURL: 'http://127.0.0.1:8080/api',
    timeout: 10000,
    //withCredentials: true, // 启用凭证
});


// 请求拦截器 - 在每次请求前加入 token，排除掉登录请求
api.interceptors.request.use(
    (config) => {
        // 检查是否是登录请求
        if (config.url !== '/admin/login') {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);


// 响应拦截器 - 处理全局的响应错误
api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('API 请求错误: ', error);
        return Promise.reject(error);
    }
);

// 定义用户相关的 API 请求
export const fetchUsers = () => api.get('/admin/users'); // 获取用户信息

// 创建新用户
export const createUser = (userData) => api.post('/admin/users', userData);

// 更新用户信息
export const updateUser = (userId, userData) => api.put(`/admin/users/${userId}`, userData);

// 软删除用户（禁用）
export const softDeleteUser = (userId) => api.delete(`/admin/users/soft-delete/${userId}`);

// 硬删除用户
export const hardDeleteUser = (userId) => api.delete(`/admin/users/${userId}`);

// 切换用户禁用/启用状态
export const toggleUserStatus = (userId, isDisabled) => {
    // 注意这里 true 代表禁用，false 代表启用
    return api.put(`/admin/users/${userId}/status`, null, { params: { is_disabled: isDisabled } });
};


// 清理 JSON 字符串中的控制字符
function cleanJsonString(str) {
    // 替换所有换行符为 \n
    str = str.replace(/\r?\n/g, '\\n');

    // 替换其他控制字符
    // eslint-disable-next-line no-control-regex
    str = str.replace(/[\x00-\x1F\x7F-\x9F]/g, match => {
        return '\\u' + ('0000' + match.charCodeAt(0).toString(16)).slice(-4);
    });

    return str;
}
// 处理流式响应的函数
async function processStream(reader, decoder, emitter) {
    let result = await reader.read();
    let buffer = '';

    while (!result.done) {
        const chunk = decoder.decode(result.value);
        buffer += chunk;

        while (buffer.length > 0) {
            // 处理 [DONE] 消息
            if (buffer.includes('data: [DONE]')) {
                emitter.onmessage({ data: '[DONE]' });
                buffer = buffer.slice(buffer.indexOf('data: [DONE]') + 12);
                continue;
            }

            // 查找消息的开始
            const dataPrefix = 'data: ';
            const dataStart = buffer.indexOf(dataPrefix);
            if (dataStart === -1) {
                buffer = '';
                break;
            }

            // 找到下一个 data: 或缓冲区结束
            let nextDataStart = buffer.indexOf(dataPrefix, dataStart + dataPrefix.length);
            if (nextDataStart === -1) {
                nextDataStart = buffer.length;
            }

            // 提取当前消息
            let messageContent = buffer.slice(dataStart + dataPrefix.length, nextDataStart).trim();
            buffer = buffer.slice(nextDataStart);

            // 跳过 event: delta 消息
            if (messageContent === 'event: delta') {
                continue;
            }

            // 处理 JSON 消息
            if (messageContent.startsWith('{')) {
                try {
                    // 清理并解析 JSON
                    messageContent = cleanJsonString(messageContent);
                    const data = JSON.parse(messageContent);

                    if (data.o === 'append' && data.v) {
                        // 发送消息到组件
                        emitter.onmessage({
                            data: JSON.stringify({
                                type: 'content',
                                text: data.v
                            })
                        });
                    }
                } catch (error) {
                    console.warn('Skipping invalid JSON message:', messageContent);
                    continue;
                }
            }
        }

        result = await reader.read();
    }
}

// 聊天 API
export const chatApi = {
    sendQuestion: (data) => {
        const token = localStorage.getItem('test_token');
        if (!token) {
            throw new Error('No token found');
        }

        const response = fetch(`${api.defaults.baseURL}/user/question`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
                'Accept': 'text/event-stream',
            },
            body: JSON.stringify({
                ai_type: data.ai_type,
                content: data.content,
                conversation_id: data.conversation_id,
                stream: true
            })
        });

        return new Promise((resolve, reject) => {
            response.then(res => {
                if (!res.ok) {
                    reject(new Error(`HTTP error! status: ${res.status}`));
                    return;
                }

                const stream = res.body;
                const reader = stream.getReader();
                const decoder = new TextDecoder();

                const emitter = {
                    onmessage: null,
                    onerror: null,
                    close: () => reader.cancel(),
                    start: async function() {
                        try {
                            await processStream(reader, decoder, this);
                        } catch (error) {
                            if (this.onerror) {
                                this.onerror(error);
                            }
                        }
                    }
                };

                resolve(emitter);
            }).catch(reject);
        });
    }
};

// 文件上传
export const fileUpload = async (file) => {
    const token = localStorage.getItem('test_token');
    if (!token) {
        throw new Error('No token found');
    }

    const formData = new FormData();
    formData.append('file', file); // 添加文件到 FormData 对象

    try {
        const response = await fetch(`${api.defaults.baseURL}/user/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                // 不需要设置 'Content-Type'，fetch 自动处理 multipart/form-data
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('上传失败');
        }

        return await response.json(); // 返回 JSON 格式的结果
    } catch (error) {
        console.error("上传出错:", error);
        throw error;
    }
};

// 登录
export const login = (username, password) => api.post('/admin/login', { username, password });

export default api;
