<template>
  <div class="question">
    <h2>对话管理</h2>
    <p>这里是对话管理模块。</p>
  </div>
</template>

<script>
export default {
  name: 'AdminQuestion',
};
</script>

<style scoped>
.question {
  padding: 20px;
}

h2 {
  color: #333;
}
</style>
