<template>
  <div class="chat-container">
    <!-- Header with Config Button -->
    <div class="chat-header">
      <h2>AI 助手</h2>
    </div>
    <!-- Chat Log -->
    <div class="chat-log" ref="chatLog">
      <div v-for="(message, index) in messages" :key="index" class="message-wrapper"
           :class="{ 'message-right': message.sender === 'User' }">
        <div class="avatar" :class="{ 'avatar-right': message.sender === 'User' }">
          {{ message.sender === 'User' ? '👤' : '🤖' }}
        </div>
        <div class="message-content">
          <div class="message" :class="{
            'message-user': message.sender === 'User',
            'message-ai': message.sender === 'AI',
            'message-error': message.sender === 'System'
          }">
            <!-- 用户和系统消息使用普通文本 -->
            <span v-if="message.sender !== 'AI'" v-text="message.content"></span>
            <!-- AI 消息使用 Markdown 渲染 -->
            <div v-else v-html="message.htmlContent" class="markdown-content"></div>
            <div v-if="message.isTyping" class="typing-indicator">
              <span></span><span></span><span></span>
            </div>
          </div>
          <div class="message-time" :class="{ 'time-right': message.sender === 'User' }">
            {{ message.time }}
          </div>
        </div>
      </div>
    </div>

    <!-- Input Area -->
    <div class="input-area">
      <input v-model="userInput" placeholder="输入消息..." @keyup.enter="submitQuestion" />
      <button @click="submitQuestion" :disabled="isLoading" class="send-button">发送</button>
    </div>
  </div>
</template>
<script>
//在 script 标签内
import { ref, watch, nextTick } from 'vue';
import { chatApi } from '@/api';
import MarkdownIt from 'markdown-it';
// import emoji from 'markdown-it-emoji';
import sub from 'markdown-it-sub';
import sup from 'markdown-it-sup';
import footnote from 'markdown-it-footnote';
import deflist from 'markdown-it-deflist';
import mark from 'markdown-it-mark';
import abbr from 'markdown-it-abbr';
import anchor from 'markdown-it-anchor';
import toc from 'markdown-it-toc-done-right';
import multimdTable from 'markdown-it-multimd-table';
import taskLists from 'markdown-it-task-lists';
import highlightjs from 'markdown-it-highlightjs';
import 'highlight.js/styles/github.css';


// 配置 markdown-it 和其插件
const md = new MarkdownIt({
  html: true,        // 允许 HTML 标签
  xhtmlOut: true,    // 使用 '/' 关闭单标签
  breaks: true,      // 转换换行符为 <br>
  linkify: true,     // 自动转换 URL 为链接
  typographer: true, // 启用一些语言中立的替换 + 引号美化
  quotes: '""\'\'',  // 引号配对，外层双引号包裹，内容为双引号和单引号对
});


md//.use(emoji)
    .use(sub)
    .use(sup)
    .use(footnote)
    .use(deflist)
    .use(mark)
    .use(abbr)
    .use(anchor, {
      permalink: anchor.permalink.ariaHidden({
        placement: 'before'
      })
    })
    .use(toc, {
      listType: 'ul'
    })
    .use(multimdTable, {
      multiline: true,
      rowspan: true,
      headerless: true
    })
    .use(taskLists, {
      enabled: true,
      label: true,
      labelAfter: true
    })
    .use(highlightjs);

// 自定义渲染规则
md.renderer.rules.table_open = () => '<div class="table-container"><table class="md-table">';
md.renderer.rules.table_close = () => '</table></div>';

export default {
  setup() {
    const userInput = ref('');
    const messages = ref([]);
    const isLoading = ref(false);
    const chatLog = ref(null);
    const showConfigModal = ref(false);
    const tokenInput = ref(localStorage.getItem('token') || '');

    const formatTime = () => {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    };

    const formatContent = (text) => text.trim().replace(/\s+/g, ' ');

    const scrollToBottom = async () => {
      await nextTick();
      if (chatLog.value) chatLog.value.scrollTop = chatLog.value.scrollHeight;
    };

    watch(messages, scrollToBottom, { deep: true });

    const saveToken = () => {
      localStorage.setItem('test_token', tokenInput.value);
      showConfigModal.value = false;
    };

//submitQuestion 函数
    const submitQuestion = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        messages.value.push({
          sender: 'System',
          content: '请配置 Token 后再发送消息。',
          time: formatTime(),
        });
        return;
      }

      if (userInput.value.trim() === '') return;

      try {
        isLoading.value = true;
        const formattedInput = formatContent(userInput.value);

        // 添加用户消息
        messages.value.push({
          sender: 'User',
          content: formattedInput,
          time: formatTime(),
        });

        // 创建 AI 消息
        const messageIndex = messages.value.length;
        messages.value.push({
          sender: 'AI',
          content: '',
          displayContent: '',
          htmlContent: '',
          time: formatTime(),
          isTyping: true,
        });

        // 发送请求
        const stream = await chatApi.sendQuestion({
          ai_type: 'StarFire Model',
          content: formattedInput,
          conversation_id: 0,
          stream: true
        });

        // 设置消息处理
        stream.onmessage = (event) => {
          const data = event.data;

          if (data === '[DONE]') {
            messages.value[messageIndex].isTyping = false;
            stream.close();
            return;
          }

          try {
            const parsedData = JSON.parse(data);
            if (parsedData.type === 'content' && parsedData.text) {
              // 更新消息内容
              const currentMessage = messages.value[messageIndex];
              currentMessage.displayContent = currentMessage.displayContent + parsedData.text;
              currentMessage.content = currentMessage.displayContent;

              // 将 Markdown 转换为 HTML
              currentMessage.htmlContent = md.render(currentMessage.displayContent);

              // 强制更新视图
              messages.value = [...messages.value];
            }
          } catch (error) {
            console.error('Failed to parse message data:', error);
          }
        };

        // 设置错误处理
        stream.onerror = (error) => {
          console.error('Stream error:', error);
          messages.value.push({
            sender: 'System',
            content: '发生错误: ' + (error.message || '连接中断'),
            time: formatTime(),
          });
          stream.close();
        };

        // 开始接收数据
        await stream.start();

      } catch (error) {
        console.error('Error in submitQuestion:', error);
        messages.value.push({
          sender: 'System',
          content: '发生错误: ' + error.message,
          time: formatTime(),
        });
      } finally {
        isLoading.value = false;
        userInput.value = '';
      }
    };
    return {
      userInput,
      messages,
      isLoading,
      submitQuestion,
      chatLog,
      showConfigModal,
      tokenInput,
      saveToken
    };
  }
};
</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f1f1f1;
}

.chat-header {
  background-color: #ededed;
  padding: 10px 20px;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.config-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.config-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.config-content {
  background: #ffffff;
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  /* text-align: center; */
}

.config-content h3 {
  margin: 0 0 15px;
  font-size: 18px;
  color: #333;
}

.config-content input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  margin-bottom: 15px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.config-content input:focus {
  border-color: #07c160;
}

.config-actions {
  display: flex;
  justify-content: space-between;
}

.config-actions button {
  width: 45%;
  padding: 8px 0;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.config-actions button:first-child {
  background-color: #07c160;
}

.config-actions button:first-child:hover {
  background-color: #06ae56;
}

.config-actions button:last-child {
  background-color: #ccc;
}

.config-actions button:last-child:hover {
  background-color: #b3b3b3;
}

.config-actions {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.chat-log {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
}

.message-wrapper {
  display: flex;
  margin-bottom: 15px;
  align-items: flex-start;
}

.message-right {
  flex-direction: row-reverse;
}

.message-content {
  display: flex;
  flex-direction: column;
  max-width: 70%;
}

.avatar {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 4px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-shrink: 0;
}

.message {
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  word-break: break-word;
  white-space: pre-wrap;
}

.message-user {
  background-color: #95ec69;
  margin-right: 10px;
}

.message-ai {
  background-color: #ffffff;
  margin-left: 10px;
}

.message-error {
  background-color: #ffd2d2;
  color: #ff4444;
  margin-left: 10px;
}

.message-time {
  font-size: 12px;
  color: #999;
  margin-top: 4px;
  margin-left: 10px;
}

.time-right {
  text-align: right;
  margin-right: 10px;
}

.typing-indicator {
  display: inline-flex;
  align-items: center;
  padding: 0 5px;
}

.typing-indicator span {
  width: 4px;
  height: 4px;
  margin: 0 1px;
  background-color: #999;
  border-radius: 50%;
  display: inline-block;
  animation: typing 1s infinite ease-in-out;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

.input-area {
  background-color: #f7f7f7;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e6e6e6;
}

input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 14px;
  background-color: #fff;
}

.send-button {
  padding: 8px 20px;
  background-color: #07c160;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.send-button:disabled {
  background-color: #a1dcb9;
  cursor: not-allowed;
}

.send-button:hover:not(:disabled) {
  background-color: #06ae56;
}

.chat-log::-webkit-scrollbar {
  width: 6px;
}

.chat-log::-webkit-scrollbar-track {
  background: transparent;
}

.chat-log::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
/* Markdown 内容样式 */
.markdown-content {
  line-height: 1.6;
  text-align: left; /* 确保左对齐 */
}

.markdown-content :deep(p),
.markdown-content :deep(h1),
.markdown-content :deep(h2),
.markdown-content :deep(h3),
.markdown-content :deep(h4),
.markdown-content :deep(h5),
.markdown-content :deep(h6),
.markdown-content :deep(ul),
.markdown-content :deep(ol),
.markdown-content :deep(table),
.markdown-content :deep(blockquote) {
  text-align: left; /* 强制左对齐 */
}

.markdown-content :deep(h1),
.markdown-content :deep(h2),
.markdown-content :deep(h3),
.markdown-content :deep(h4),
.markdown-content :deep(h5),
.markdown-content :deep(h6) {
  margin: 12px 0 8px;
  font-weight: 600;
}

.markdown-content :deep(h1) {
  font-size: 1.6em;
  margin-top: 16px;
}

.markdown-content :deep(h2) {
  font-size: 1.4em;
  margin-top: 14px;
}

.markdown-content :deep(h3) {
  font-size: 1.2em;
  margin-top: 12px;
}

.markdown-content :deep(p) {
  margin: 8px 0;
}

.markdown-content :deep(ul),
.markdown-content :deep(ol) {
  padding-left: 20px;
  margin: 8px 0;
}

.markdown-content :deep(li) {
  margin: 4px 0;
}

.markdown-content :deep(code) {
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 3px;
  font-family: monospace;
}

.markdown-content :deep(pre) {
  background-color: #f5f5f5;
  padding: 12px;
  border-radius: 4px;
  overflow-x: auto;
  margin: 8px 0;
}

.markdown-content :deep(blockquote) {
  border-left: 4px solid #ddd;
  padding-left: 12px;
  margin: 8px 0;
  color: #666;
}

.markdown-content :deep(strong) {
  font-weight: 600;
}

.markdown-content :deep(em) {
  font-style: italic;
}

.markdown-content :deep(a) {
  color: #0366d6;
  text-decoration: none;
}

.markdown-content :deep(a:hover) {
  text-decoration: underline;
}

.markdown-content :deep(table) {
  border-collapse: collapse;
  width: 100%;
  margin: 8px 0;
}

.markdown-content :deep(th),
.markdown-content :deep(td) {
  border: 1px solid #ddd;
  padding: 6px 8px;
}

.markdown-content :deep(th) {
  background-color: #f5f5f5;
}
</style>
